import * as api from '@amc-technology/davinci-api';
import { IInteraction } from '@amc-technology/davinci-api';
import { IActivity } from './Model/IActivity';
import { IActivityDetails } from './Model/IActivityDetails';
import { IActivityFields } from './Model/IActivityFields';
import { Injectable } from '@angular/core';
import { ICadDisplayConfig } from './Model/ICadDisplayConfig';
import { LoggerService } from './logger.service';
@Injectable()
export class StorageService {
  public whoList: {
    [scenarioId: string]: IActivityDetails[]
  };
  public whatList: {
    [scenarioId: string]: IActivityDetails[]
  };
  public currentScenarioId: string;
  public subject: string;
  public workingRecentScenarioId: string;
  public activityList: {
    [scenarioId: string]: IActivity
  };
  public displayCadList: {
    [scenarioId: string]: {
      [key: string]: string
    }
  };
  public displayCadData: boolean;
  public scenarioToCADMap: {
    [scenarioId: string]: any
  };
  public recentScenarioIdList: string[];
  public activeScenarioIdList: string[];
  public expiredScenarioIdList: string[];
  public searchRecordList: {
    [scenarioId: string]: api.IRecordItem[]
  };
  public selectedWhatValueList: {
    [key: string]: string;
  };
  public selectedWhoValueList: {
    [key: string]: string;
  };
  public selectedSearchRecordList: {
    [key: string]: string;
  };
  public nameChangesList: string[];
  public relatedToChangesList: string[];
  public maxExpiredItems: Number;
  public maxRecentItems: Number;
  public savedActivityFields: {
    [scenarioId: string]: IActivityFields
  };

  public callFromObjects: string[];
  public regardingObjects: string[];

  public isLogout = false;

  constructor(private loggerService: LoggerService) {
    this.whoList = {};
    this.whatList = {};
    this.currentScenarioId = null;
    this.subject = null;
    this.workingRecentScenarioId = null;
    this.activityList = {};
    this.recentScenarioIdList = [];
    this.activeScenarioIdList = [];
    this.expiredScenarioIdList = [];
    this.searchRecordList = {};
    this.selectedWhatValueList = {};
    this.selectedWhoValueList = {};
    this.selectedSearchRecordList = {};
    this.nameChangesList = [];
    this.relatedToChangesList = [];
    this.maxRecentItems = 0;
    this.maxExpiredItems = 2;
    this.displayCadList = {};
    this.displayCadData = false;
    this.scenarioToCADMap = {};
    this.savedActivityFields = {};
    this.callFromObjects = [];
    this.regardingObjects = [];
  }

  public setCallFromObjects(callFromObjects: string[]) {
    this.callFromObjects = callFromObjects;
  }

  public setRegardingObjects(regardingObjects: string[]) {
    this.regardingObjects = regardingObjects;
  }

  public setCurrentScenarioId(currentScenarioId: string) {
    try {
      this.currentScenarioId = currentScenarioId;
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Set Current Scenario ID for Scenario ID : '
        + currentScenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public getActivity(scenarioId: string = null): IActivity {
    try {
      if (!scenarioId) {
        scenarioId = this.currentScenarioId;
      }
      if (this.activityList[scenarioId]) {
        return this.activityList[scenarioId];
      }
      return null;
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Get Activity for Scenario ID : '
        + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public recentActivityListContains(scenarioId: string): boolean {
    try {
      return (this.activityList[scenarioId] && !this.activityList[scenarioId].IsActive) ? true : false;
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Check if Exists in Recent Activity List for Scenario ID : '
        + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public removeRecentActivity(scenarioId: string) {
    try {
      if ( this.recentScenarioIdList.indexOf(scenarioId) !== -1) {
        const index = this.recentScenarioIdList.indexOf(scenarioId);
        this.recentScenarioIdList.splice(index, 1);
        this.clearWhatList(scenarioId);
        this.clearWhoList(scenarioId);
        delete this.savedActivityFields[scenarioId];
        delete this.activityList[scenarioId];
        this.storeToLocalStorage();
      }
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Revmove Recent Activity for Scenario ID : '
        + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private addRecentActivity(activity: IActivity, isAutoSave: boolean) {
    try {
      const deleteExpiredActivity = ((this.expiredScenarioIdList.length === this.maxExpiredItems) && this.maxRecentItems === 0);
      if ((Object.keys(this.recentScenarioIdList).length === this.maxRecentItems) || this.maxRecentItems === 0) {
        const scenarioId = (this.maxRecentItems === 0) ?
          (deleteExpiredActivity ? this.expiredScenarioIdList.pop() : activity.ScenarioId) : this.recentScenarioIdList.pop();
        this.clearWhatList(scenarioId);
        this.clearWhoList(scenarioId);
        delete this.selectedWhatValueList[scenarioId];
        delete this.selectedWhoValueList[scenarioId];
        if (this.maxRecentItems !== 0 || deleteExpiredActivity) {
          delete this.savedActivityFields[scenarioId];
          delete this.activityList[scenarioId];
        }
      }
      if (this.maxRecentItems !== 0) {
        this.recentScenarioIdList.unshift(activity.ScenarioId);
        if (this.activityList[activity.ScenarioId].IsUnSaved && !isAutoSave) {
          this.workingRecentScenarioId = activity.ScenarioId;
        }
      } else {
        this.expiredScenarioIdList.unshift(activity.ScenarioId);
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Add Recent Activity for Scenario ID : '
        + activity.ScenarioId + ', Activity Info : ' + JSON.stringify(activity) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public addActivity(activity: IActivity) {
    try {
      this.activityList[activity.ScenarioId] = activity;
      this.activeScenarioIdList.push(activity.ScenarioId);
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Add Activity for Scenario ID : '
        + activity.ScenarioId + ', Activity Info : ' + JSON.stringify(activity) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateActivity(activity: IActivity) {
    try {
      if (this.activityList[activity.ScenarioId]) {
        activity.LastUpdated = new Date();
        this.activityList[activity.ScenarioId] = activity;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Update Activity for Scenario ID : '
        + activity.ScenarioId + ', Activity Info : ' + JSON.stringify(activity) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private removeActivity(scenarioId: string, isAutoSave: boolean) {
    try {
      if (this.activityList[scenarioId]) {
        this.addRecentActivity(this.activityList[scenarioId], isAutoSave);
        this.activeScenarioIdList = this.activeScenarioIdList.filter(id => id !== scenarioId);
      } else {
        this.clearWhoList(scenarioId);
        this.clearWhatList(scenarioId);
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Remove Activity for Scenario ID : '
        + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private clearScenarioCadMap(scenarioId: string) {
    delete this.scenarioToCADMap[scenarioId];
    this.storeToLocalStorage();
  }

  private activityListContains(scenarioId: string): boolean {
    try {
      return (this.activityList[scenarioId]) ? true : false;
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Check if Exists in Activity List for Scenario ID : '
        + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public getSubject(): string {
    try {
      return this.getActivity().Subject;
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Get Subject. Error Information : ' + JSON.stringify(error));
    }
  }

  public setSubject(subject: string, scenarioId: string) {
    try {
      if (this.activityList[scenarioId]) {
        this.activityList[scenarioId].Subject = subject;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Set Subject for Scenario ID : '
        + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public getDescription(): string {
    try {
      return this.getActivity().Description;
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Get Description. Error Information : ' + JSON.stringify(error));
    }
  }

  public setDescription(description: string, scenarioId: string) {
    try {
      if (this.activityList[scenarioId]) {
        this.activityList[scenarioId].Description = description;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Set Description for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private setActivityWhoObject(whoObject: IActivityDetails, scenarioId: string) {
    try {
      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
        this.activityList[scenarioId].WhoObject = whoObject;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Set Activity Who Object for Scenario ID : '
      + scenarioId + ', What Object : ' + JSON.stringify(whoObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private setActivityWhatObject(whatObject: IActivityDetails, scenarioId: string) {
    try {
      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
        this.activityList[scenarioId].WhatObject = whatObject;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Set Activity What Object for Scenario ID : '
      + scenarioId + ', What Object : ' + JSON.stringify(whatObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public UpdateWhoObjectSelectionChange(whoObjectId: string, scenarioId: string) {
    try {
      const currentWhoObject = this.getWhoObject(whoObjectId, scenarioId);
      if (this.currentScenarioId === scenarioId) {
        this.nameChangesList.push(scenarioId);
      }
      this.setActivityWhoObject(currentWhoObject, scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Update Who Object Selection Change for Scenario ID : '
      + scenarioId + ', Who Object ID : ' + whoObjectId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public UpdateWhatObjectSelectionChange(whatObjectId: string, scenarioId: string) {
    try {
      const currentWhatObject = this.getWhatObject(whatObjectId, scenarioId);
      if (this.currentScenarioId === scenarioId) {
        this.relatedToChangesList.push(scenarioId);
      }
      this.setActivityWhatObject(currentWhatObject, scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Update What Object Selection Change for Scenario ID : '
      + scenarioId + ', What Object ID : ' + whatObjectId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private getWhatObject(whatId: string, scenarioId: string): IActivityDetails {
    try {
      return this.whatList[scenarioId].find(item => item.objectId === whatId);
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Get What Object for Scenario ID : '
      + scenarioId + ', What Id : ' + whatId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private getWhoObject(whoId: string, scenarioId: string): IActivityDetails {
    try {
      return this.whoList[scenarioId].find(item => item.objectId === whoId);
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Get Who Object for Scenario ID : '
      + scenarioId + ', Who Id : ' + whoId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private whatListContains(whatObject: IActivityDetails, scenarioId: string): boolean {
    try {
      if (scenarioId) {
        const interactionWhatList = this.whatList[scenarioId];
        if (interactionWhatList) {
          return (this.whatList[scenarioId].find(item => item.objectId === whatObject.objectId)) ? true : false;
        }
      }
      return false;
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Check if Exits in What List for Scenario ID : '
      + scenarioId + ', What Object : ' + JSON.stringify(whatObject) + '. Error Information : ' + JSON.stringify(error));
    }

  }

  private whoListContains(whoObject: IActivityDetails, scenarioId: string): boolean {
    try {
      if (scenarioId) {
        const interactionWhoList = this.whoList[scenarioId];
        if (interactionWhoList) {
          return (this.whoList[scenarioId].find(item => item.objectId === whoObject.objectId)) ? true : false;
        }
      }
      return false;
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Check if Exits in Who List for Scenario ID : '
      + scenarioId + ', Who Object : ' + JSON.stringify(whoObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setWhatList(activityDetails: IActivityDetails, scenarioId: string) {
    try {
      const interactionWhatList = this.whatList[scenarioId];

      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
      }

      if (!interactionWhatList) {
        this.whatList[scenarioId] = [];
      }
      this.whatList[scenarioId].push(activityDetails);
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Set What List for Scenario ID : '
      + scenarioId + ', Activity Details : ' + JSON.stringify(activityDetails) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setWhoList(activityDetails: IActivityDetails, scenarioId: string) {
    try {
      const interactionWhoList = this.whoList[scenarioId];

      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
      }

      if (!interactionWhoList) {
        this.whoList[scenarioId] = [];
      }
      this.whoList[scenarioId].push(activityDetails);
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Set Who List for Scenario ID : '
      + scenarioId + ', Activity Details : ' + JSON.stringify(activityDetails) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private clearWhatList(scenarioId: string) {
    try {
      delete this.whatList[scenarioId];
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Clear What List for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private clearWhoList(scenarioId: string) {
    try {
      delete this.whoList[scenarioId];
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Clear Who List for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateWhoWhatLists(activityObject: IActivityDetails, scenarioId: string) {
    try {
      if (this.callFromObjects.length === 0 && this.regardingObjects.length === 0) {
        if (!this.whatListContains(activityObject, scenarioId)) {
          this.setWhatList(activityObject, scenarioId);
        }
        if (!this.whoListContains(activityObject, scenarioId)) {
          this.setWhoList(activityObject, scenarioId);
        }
        if (this.activeScenarioIdList.indexOf(scenarioId) >= 0) {
          if (this.relatedToChangesList.indexOf(scenarioId) < 0) {
            this.selectedWhatValueList[scenarioId] = activityObject.objectId;
            this.setActivityWhatObject(activityObject, scenarioId);
          }
          if (this.nameChangesList.indexOf(scenarioId) < 0) {
            this.selectedWhoValueList[scenarioId] = activityObject.objectId;
            this.setActivityWhoObject(activityObject, scenarioId);
          }
        }
      }
      if (this.callFromObjects.length !== 0 && this.regardingObjects.length === 0) {
        this.callFromObjectsHandler(activityObject, scenarioId);
      }
      if (this.callFromObjects.length === 0 && this.regardingObjects.length !== 0) {
        this.regardingObjectsHandler(activityObject, scenarioId);
      }
      if (this.callFromObjects.length !== 0 && this.regardingObjects.length !== 0) {
        this.callFromObjectsHandler(activityObject, scenarioId);
        this.regardingObjectsHandler(activityObject, scenarioId);
      }
      this.compareActivityFields(scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Update Who What Lists for Scenario ID : '
      + scenarioId + ', Activity Object : ' + JSON.stringify(activityObject)  + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public callFromObjectsHandler(activityObject: IActivityDetails, scenarioId: string) {
    if (this.callFromObjects.indexOf(activityObject.objectType) > -1) {
      if (!this.whoListContains(activityObject, scenarioId)) {
        this.setWhoList(activityObject, scenarioId);
      }
      if (this.activeScenarioIdList.indexOf(scenarioId) >= 0) {
        if (this.nameChangesList.indexOf(scenarioId) < 0) {
          this.selectedWhoValueList[scenarioId] = activityObject.objectId;
          this.setActivityWhoObject(activityObject, scenarioId);
        }
      }
    } else {
      if (!this.whatListContains(activityObject, scenarioId)) {
        this.setWhatList(activityObject, scenarioId);
      }
      if (this.activeScenarioIdList.indexOf(scenarioId) >= 0) {
        if (this.relatedToChangesList.indexOf(scenarioId) < 0) {
          this.selectedWhatValueList[scenarioId] = activityObject.objectId;
          this.setActivityWhatObject(activityObject, scenarioId);
        }
      }
    }
  }

  public regardingObjectsHandler(activityObject: IActivityDetails, scenarioId: string) {
    if (this.regardingObjects.indexOf(activityObject.objectType) > -1) {
      if (!this.whatListContains(activityObject, scenarioId)) {
        this.setWhatList(activityObject, scenarioId);
      }
      if (this.activeScenarioIdList.indexOf(scenarioId) >= 0) {
        if (this.relatedToChangesList.indexOf(scenarioId) < 0) {
          this.selectedWhatValueList[scenarioId] = activityObject.objectId;
          this.setActivityWhatObject(activityObject, scenarioId);
        }
      }
    } else {
      if (!this.whoListContains(activityObject, scenarioId)) {
        this.setWhoList(activityObject, scenarioId);
      }
      if (this.activeScenarioIdList.indexOf(scenarioId) >= 0) {
        if (this.nameChangesList.indexOf(scenarioId) < 0) {
          this.selectedWhoValueList[scenarioId] = activityObject.objectId;
          this.setActivityWhoObject(activityObject, scenarioId);
        }
      }
    }
  }

  public RetrieveEntityFromWhatList(entityType: string, param: any) {
    const interactionWhatList = this.whatList[this.currentScenarioId];
    if (interactionWhatList) {
      const assocEntityList = interactionWhatList.filter(item => item.objectType === entityType);
      if (assocEntityList.length === 1) {
        param[entityType + 'id'] = assocEntityList[0].objectId;
        param[entityType + 'name'] = assocEntityList[0].displayName;
      }
    }
    return param;
  }

  public setsearchRecordList(searchRecords: api.IRecordItem[], scenarioId: string) {
    try {
      this.searchRecordList[scenarioId] = searchRecords;

      if (searchRecords.length > 1) {
        this.selectedSearchRecordList[scenarioId] = 'default';
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Set Search Record List for Scenario ID : '
      + scenarioId + ', Search Records : ' + JSON.stringify(searchRecords)  + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private clearSearchRecordList(scenarioId: string) {
    try {
      delete this.selectedSearchRecordList[scenarioId];
      delete this.searchRecordList[scenarioId];
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Clear Search Record List for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateActivityFields(scenarioId: string) {
    try {
      this.setActivityField(scenarioId, 'LastUpdated', new Date());
      const activityFields = this.getActivityFields(scenarioId);
      if (!this.savedActivityFields) {
        this.savedActivityFields = {};
      }
      this.savedActivityFields[scenarioId] = activityFields;
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Update Activity Fields for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public getActivityFields(scenarioId: string): IActivityFields {
    try {
      const activityFields: IActivityFields = {
        whoId: this.selectedWhoValueList[scenarioId] ? this.selectedWhoValueList[scenarioId] :
          this.activityList[scenarioId].WhoObject.objectId,
        whatId: this.selectedWhatValueList[scenarioId] ? this.selectedWhatValueList[scenarioId] :
          this.activityList[scenarioId].WhatObject.objectId,
        subject: this.getActivity(scenarioId).Subject,
        description: this.getActivity(scenarioId).Description
      };
      return activityFields;
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Get Activity Fields for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setActivityField(scenarioId: string, activityField: string, activityValue: any) {
    try {
      if (this.activityList[scenarioId]) {
        if (activityField !== 'LastUpdated') {
          this.activityList[scenarioId]['LastUpdated'] = new Date();
        }

        this.activityList[scenarioId][activityField] = activityValue;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Set Activity Field for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateTotalInteractionTime(scenarioId: string, cadActivityMap: Object) {
    try {
      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
        let activityModified = false;

        // Close any interacions that are not disconnected
        for (const i of Object.keys(this.activityList[scenarioId].DurationOnInteractions)) {
          this.updateInteractionDurationActivity(scenarioId, i, true);
        }

        if (this.activityList[scenarioId].DurationOnInteractions) {
          this.activityList[scenarioId].CallDurationInSeconds = 0;
          for (const i of Object.keys(this.activityList[scenarioId].DurationOnInteractions)) {
            if (this.activityList[scenarioId].DurationOnInteractions[i].interactionDuration) {
              this.activityList[scenarioId].CallDurationInSeconds += this.activityList[scenarioId].DurationOnInteractions[i].interactionDuration;
              activityModified = true;
            }
          }
        }

        if (activityModified) {
          this.storeToLocalStorage();
          this.updateActivityCadField(scenarioId, cadActivityMap, 'DetailedCallDuration', JSON.stringify(this.activityList[scenarioId].DurationOnInteractions));
          this.updateActivityCadField(scenarioId, cadActivityMap, 'CallDurationInSeconds', this.activityList[scenarioId].CallDurationInSeconds.toString());
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : updateTotalInteractionTime for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateInteractionDurationActivity(scenarioId: string, interactionId: string, isDisconnected: boolean) {
    try {
      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
        let activityModified = false;
        if (!this.activityList[scenarioId].DurationOnInteractions ||
          !this.activityList[scenarioId].DurationOnInteractions[interactionId]) {
          if (!isDisconnected) {
            // If the interaction is Not Disconnected for the first time
            this.activityList[scenarioId].DurationOnInteractions[interactionId] = {
              interactionDuration: 0,
              DurationTracking: []
            };

            this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[0] = {
              start: new Date(),
              end: undefined
            };

            activityModified = true;
            this.loggerService.logger.logDebug('Dynamics - Storage : updateInteractionDurationActivity First time for Scenario ID : '
            + scenarioId + ' InteractionId : ' + interactionId);
          } // else ignore since the call connected didn't come yet or this must be an extra disconnect
        } else { // Interaction was strted earlier
          const trackingLength = this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking.length;
          if (isDisconnected && trackingLength > 0) {
            if (!this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end &&
            this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start) {
              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end = new Date();
              if (typeof this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start === 'string') {
                // If Start Date got loaded from localstorage then, convert it back to Date
                this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start =
                  new Date(this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start);
              }

              if (!this.activityList[scenarioId].DurationOnInteractions[interactionId].interactionDuration) {
                this.activityList[scenarioId].DurationOnInteractions[interactionId].interactionDuration = 0;
              }

              this.activityList[scenarioId].DurationOnInteractions[interactionId].interactionDuration +=
                Math.round( (this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end.getTime() -
                  this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start.getTime()) / 1000 );

              activityModified = true;
              this.loggerService.logger.logDebug('Dynamics - Storage : updateInteractionDurationActivity call Disconnected for Scenario ID : '
              + scenarioId + ' InteractionId : ' + interactionId );
            }
          }

          if (!isDisconnected) {
            if (this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end &&
            this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start) {

              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength] = {
                start: new Date(),
                end: undefined
              };

              activityModified = true;
              this.loggerService.logger.logDebug('Dynamics - Storage : updateInteractionDurationActivity call started Scenario ID : '
              + scenarioId + ' InteractionId : ' + interactionId );
            }
          }
        }

        if (activityModified) {
          this.storeToLocalStorage();
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : updateInteractionDurationActivity for Scenario ID : '
      + scenarioId + ' InteractionId : ' + interactionId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateTotalHoldTime(scenarioId: string, cadActivityMap: Object) {
    try {
      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
        let activityModified = false;

        // Close any Hold interacions
        for (const i of Object.keys(this.activityList[scenarioId].HoldDurationOnInteractions)) {
          this.updateHoldInteractionActivityField(scenarioId, i, false);
        }

        if (this.activityList[scenarioId].HoldDurationOnInteractions) {
          this.activityList[scenarioId].HoldDurationInSeconds = 0;
          this.activityList[scenarioId].NumberOfHolds = 0;
          for (const i of Object.keys(this.activityList[scenarioId].HoldDurationOnInteractions)) {
            if (this.activityList[scenarioId].HoldDurationOnInteractions[i].holdDuration) {
              this.activityList[scenarioId].HoldDurationInSeconds += this.activityList[scenarioId].HoldDurationOnInteractions[i].holdDuration;
              activityModified = true;
            }

            if (this.activityList[scenarioId].HoldDurationOnInteractions[i].numberOfHolds) {
              this.activityList[scenarioId].NumberOfHolds += this.activityList[scenarioId].HoldDurationOnInteractions[i].numberOfHolds;
              activityModified = true;
            }
          }
        }

        if (activityModified) {
          this.storeToLocalStorage();
          this.updateActivityCadField(scenarioId, cadActivityMap, 'DetailedHoldDuration', JSON.stringify(this.activityList[scenarioId].HoldDurationOnInteractions));
          this.updateActivityCadField(scenarioId, cadActivityMap, 'HoldDurationInSeconds', this.activityList[scenarioId].HoldDurationInSeconds.toString());
          this.updateActivityCadField(scenarioId, cadActivityMap, 'NumberOfHolds', this.activityList[scenarioId].NumberOfHolds.toString());
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : updateTotalHoldTime for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateHoldInteractionActivityField(scenarioId: string, interactionId: string, isHold: boolean) {
    try {
      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
        let activityModified = false;
        if (!this.activityList[scenarioId].HoldDurationOnInteractions ||
          !this.activityList[scenarioId].HoldDurationOnInteractions[interactionId]) {
          if (isHold) {
            // If the interaction is on hold for the first time
            this.activityList[scenarioId].HoldDurationOnInteractions[interactionId] = {
              numberOfHolds: 0,
              holdDuration: 0,
              holdDurationTracking: []
            };

            this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[0] = {
              start: new Date(),
              end: undefined
            };

            activityModified = true;
            this.loggerService.logger.logDebug('Dynamics - Storage : updateHoldInteractionActivityField call OnHold First time for Scenario ID : '
            + scenarioId + ' InteractionId : ' + interactionId + ' isHold ' + isHold);
          } // else ignore since the call never went to hold
        } else { // Interaction was atleast once on OnHold before
          const trackingLength = this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking.length;
          if (!isHold && trackingLength > 0) {
            if (!this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end &&
            this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start) {
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end = new Date();
              if (typeof this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start === 'string') {
                // If Start Date got loaded from localstorage then, convert it back to Date
                this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start =
                  new Date(this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start);
              }

              if (!this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDuration) {
                this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDuration = 0;
              }

              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDuration +=
                Math.round( (this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end.getTime() -
                  this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start.getTime()) / 1000 );

              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].numberOfHolds++;
              activityModified = true;
              this.loggerService.logger.logDebug('Dynamics - Storage : updateHoldInteractionActivityField call Not OnHold for Scenario ID : '
              + scenarioId + ' InteractionId : ' + interactionId + ' Number of Holds  ' + trackingLength + ' isHold ' + isHold);
            }
          }

          if (isHold) {
            if (this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end &&
            this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start) {
              // Make sure we take into account we handle multiple HOLD events when call is OnHOLD but we only track once
              // Once when the last HOLD / Retrieve are complete we take on next HOLD event as next OnHOLD occurance
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength] = {
                start: new Date(),
                end: undefined
              };

              activityModified = true;
              this.loggerService.logger.logDebug('Dynamics - Storage : updateHoldInteractionActivityField call OnHold for Scenario ID : '
              + scenarioId + ' InteractionId : ' + interactionId + ' Number of Holds  ' + trackingLength + ' isHold ' + isHold);
            }
          }
        }

        if (activityModified) {
          this.storeToLocalStorage();
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : updateHoldInteractionActivityField for Scenario ID : '
      + scenarioId + ' InteractionId : ' + interactionId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateRecentWorkItem(recentWorkItem: Object, scenarioId: string, activityLayout: Object) {
    if ((!this.whoListContains(recentWorkItem['WhoObject'], scenarioId)) && (recentWorkItem['WhoObject'].objectId)) {
      this.setWhoList(recentWorkItem['WhoObject'], scenarioId);
    }
    this.selectedWhoValueList[scenarioId] = recentWorkItem['WhoObject'].objectId ? recentWorkItem['WhoObject'].objectId :
      'UserSelectedForEmptyRecord';
    if ((!this.whatListContains(recentWorkItem['WhatObject'], scenarioId)) && (recentWorkItem['WhatObject'].objectId)) {
      this.setWhatList(recentWorkItem['WhatObject'], scenarioId);
    }
    this.selectedWhatValueList[scenarioId] = recentWorkItem['WhatObject'].objectId ? recentWorkItem['WhatObject'].objectId :
      'UserSelectedForEmptyRecord';
    const activity = this.getActivity(scenarioId);
    const refFields: string[] = activityLayout[activity.ChannelType]['Fields'];
    const lookupFields: string[] = activityLayout[activity.ChannelType]['LookupFields'];
    for (let field of refFields) {
      if (lookupFields[field]) {
        field = lookupFields[field];
      }
      this.setActivityField(scenarioId, field, recentWorkItem[field]);
    }
    this.updateActivityFields(scenarioId);
    this.compareActivityFields(scenarioId);
  }

  public compareActivityFields(scenarioId: string) {
    try {
      const latestActivityData = this.getActivityFields(scenarioId);
      const keys = Object.keys(this.savedActivityFields[scenarioId]);
      for (const key in keys) {
        if (this.savedActivityFields[scenarioId][keys[key]] !== latestActivityData[keys[key]]) {
          this.activityList[scenarioId].IsUnSaved = true;
          this.storeToLocalStorage();
          return;
        }
      }
      this.activityList[scenarioId].IsUnSaved = false;
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Compare Activity Fields for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }

  }

  public storeToLocalStorage() {
    try {
      if (this.isLogout) {
        return;
      } else {
        const prevScenarioRecord = localStorage.getItem('scenario');
        const scenarioRecord = JSON.stringify({
          activityList: this.activityList,
          currentScenarioId: this.currentScenarioId,
          searchRecordList: this.searchRecordList,
          whatList: this.whatList,
          whoList: this.whoList,
          recentScenarioIdList: this.recentScenarioIdList,
          activeScenarioIdList: this.activeScenarioIdList,
          expiredScenarioIdList: this.expiredScenarioIdList,
          lstRelatedToChanges: this.relatedToChangesList,
          lstNameChanges: this.nameChangesList,
          displayCadList: this.displayCadList,
          selectedWhatValueList: this.selectedWhatValueList,
          selectedWhoValueList: this.selectedWhoValueList,
          selectedSearchRecordList: this.selectedSearchRecordList,
          scenarioToCADMap: this.scenarioToCADMap,
          savedActivityFields: this.savedActivityFields
        });
        this.loggerService.logger.logDebug('Dynamics - Storage : Storing to Local Storage. Scenario before update : ' + prevScenarioRecord);
        localStorage.setItem('scenario', scenarioRecord);
        this.loggerService.logger.logDebug('Dynamics - Storage : Storing to Local Storage. Scenario after update : ' + scenarioRecord);
      }
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Storing to Local Storage. Error Information : '
      + JSON.stringify(error));
    }

  }

  public syncWithLocalStorage() {
    try {
      const scenarioRecord = localStorage.getItem('scenario');
      this.loggerService.logger.logDebug('Dynamics - Storage : Syncing with Local Storage. Scenario information : '
      + scenarioRecord);
      const browserStorage = JSON.parse(scenarioRecord);
      if (browserStorage) {
        this.activityList = browserStorage.activityList;
        this.currentScenarioId = browserStorage.currentScenarioId;
        this.searchRecordList = browserStorage.searchRecordList;
        this.whatList = browserStorage.whatList;
        this.whoList = browserStorage.whoList;
        this.recentScenarioIdList = browserStorage.recentScenarioIdList;
        this.activeScenarioIdList = browserStorage.activeScenarioIdList;
        this.expiredScenarioIdList = browserStorage.expiredScenarioIdList;
        this.relatedToChangesList = browserStorage.lstRelatedToChanges;
        this.nameChangesList = browserStorage.lstNameChanges;
        this.displayCadList = browserStorage.displayCadList;
        this.selectedWhatValueList = browserStorage.selectedWhatValueList;
        this.selectedWhoValueList = browserStorage.selectedWhoValueList;
        this.selectedSearchRecordList = browserStorage.selectedSearchRecordList;
        this.scenarioToCADMap = browserStorage.scenarioToCADMap;
        this.savedActivityFields = browserStorage.savedActivityFields;
      }
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Syncing with Local Storage. Error Information : '
      + JSON.stringify(error));
    }
  }

  public onInteractionDisconnect(scenarioId: string, isAutoSave: boolean) {
    try {
      this.loggerService.logger.logDebug('Dynamics - Storage : Received Interaction Disconnect Event for Scenario ID : ' + scenarioId);
      this.loggerService.logger.logDebug('Dynamics - Storage : Removing Activity for Scenario ID : ' + scenarioId);
      this.removeActivity(scenarioId, isAutoSave);
      this.nameChangesList = this.nameChangesList.filter(item => item !== scenarioId);
      this.relatedToChangesList = this.relatedToChangesList.filter(item => item !== scenarioId);
      delete this.displayCadList[scenarioId];
      this.clearSearchRecordList(scenarioId);
      this.clearScenarioCadMap(scenarioId);
      if (this.currentScenarioId === scenarioId) {
        if (this.activeScenarioIdList.length > 0) {
          this.setCurrentScenarioId(this.activeScenarioIdList[0]);
        } else {
          this.setCurrentScenarioId(null);
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : On Interaction Disconnect. Error Information : '
      + JSON.stringify(error));
    }
  }

  public updateActivityCadField(scenarioId: string, cadActivityMap: Object, cadKey: string, cadValue: string) {
    if (cadActivityMap && cadActivityMap[cadKey]) {
      this.setActivityField(scenarioId, 'LastUpdated', new Date());
      const objActivity = this.getActivity(scenarioId);
      if (!objActivity.CadFields) {
        objActivity.CadFields = {};
      }
      objActivity.CadFields[cadActivityMap[cadKey]] = cadValue;
      this.updateActivity(objActivity);
    }
  }

  public updateCadFields(interaction: IInteraction, cadActivityMap: Object, cadDisplayMap: ICadDisplayConfig) {
    try {
      const isInteractionCurrent = this.activityListContains(interaction.scenarioId);
      const isInteractionRecent = this.recentActivityListContains(interaction.scenarioId);
      if (interaction.details && interaction.details.fields && !this.scenarioToCADMap[this.currentScenarioId]) {
        this.scenarioToCADMap[interaction.scenarioId] = interaction.details.fields;
        this.storeToLocalStorage();
      }
      if (isInteractionCurrent || isInteractionRecent) {
        if (interaction.details) {
          for (const key in cadActivityMap) {
            if (interaction.details.fields[key]) {
              const objActivity = this.getActivity(interaction.scenarioId);
              if (!objActivity.CadFields) {
                objActivity.CadFields = {};
              }
              objActivity.CadFields[cadActivityMap[key]] = interaction.details.fields[key].Value;
              this.updateActivity(objActivity);
            }
          }
        }
      }


      if (interaction.details && cadDisplayMap.DisplayCad && !isInteractionRecent) {
        for (const key in cadDisplayMap.CadFields) {
          if (interaction.details.fields[key]) {
            if (!this.displayCadList[interaction.scenarioId]) {
              this.displayCadList[interaction.scenarioId] = {};
            }
            this.displayCadList[interaction.scenarioId][cadDisplayMap.CadFields[key]] = interaction.details.fields[key].Value;
            this.storeToLocalStorage();
          }
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Dynamics - Storage : ERROR : Updating CAD Fields for Scenario ID : '
      + interaction.scenarioId + ', Interaction Details : ' + JSON.stringify(interaction) + ', CAD Activity Map : '
      + JSON.stringify(cadActivityMap) + '. Error information : ' + JSON.stringify(error));
    }
  }
}
