import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home-dynamics.component';
import { ActivityComponent } from './activity/activity-dynamics.component';
import { CreateNewComponent } from './create-new/create-new.component';
import { CallerInformationComponent } from './caller-information/caller-information.component';
import { StorageService } from './storage.service';
import { LoggerService } from './logger.service';
import { ConfigurationService } from './configuration.service';
import { RecentactivitesComponent } from './recentactivites/recentactivites.component';
import { CadDisplayComponent } from './cad-display/cad-display.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ActivityComponent,
    CreateNewComponent,
    CallerInformationComponent,
    RecentactivitesComponent,
    CadDisplayComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' }
    ])
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigurationService,
        loggerService: LoggerService
      ) => async () => {
        await configService.loadConfigurationData();
        await loggerService.initialize();
      },
      deps: [ConfigurationService, LoggerService],
      multi: true
    },
    LoggerService,
    StorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
