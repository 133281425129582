import { Component, AfterViewChecked, ElementRef } from '@angular/core';
import * as api from '@amc-technology/davinci-api';

declare let customAmcCif: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewChecked {
  height: number;
  heightNonZero: boolean;
  el: ElementRef;

  constructor(el: ElementRef) {
    this.height = 500;
    this.heightNonZero = false;
    this.el = el;
  }

  ngAfterViewChecked(): void {
    this.setHeight();
  }

  private setHeight(): void {
    const newHeight = this.getHeight();
    if (this.heightNonZero && newHeight !== 5) {
      if (newHeight !== this.height) {
        this.height = newHeight;

        api.setAppHeight(this.height);
      }
    }
  }

  private getHeight(): number {
    if (this.el.nativeElement.children[0].scrollHeight !== 0) {
      this.heightNonZero = true;
    }
    return this.el.nativeElement.children[0].scrollHeight + 5;
  }
}
